<template>
    <v-card
        class="dark-bg"
        flat
        dark
        :max-width="maxCardWidth"
        @click.stop="dialog = true"
    >

        <slot></slot>

        <v-dialog v-model="dialog">
          <v-card
            class="dark-bg"
            dark
            @click.stop="dialog = false"
            :min-width="minDialogWidth"
          >

          <slot></slot>

          </v-card>
        </v-dialog>
    </v-card>
</template>

<style scoped>
.dark-bg {
  background: var(--v-primary-base) !important;
}
</style>

<script>
export default {
    name: 'Expandable',
    props: {
        minDialogWidth: {
            type: String,
            required: false,
            default: "auto"
        },
        maxCardWidth: {
            type: String,
            required: false,
            default: "auto"
        }
    },
    data() {
        return {
            dialog: false
        }
    }
}
</script>
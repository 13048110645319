<template>
    <div class="bg global-blue">
      <v-container>
        <v-row>
          <v-col>
            <h1 class="global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</h1>
            <h1 class="global-title hidden-sm-and-down">{{ translations.title[locale] }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <h2 class="ma-4 global-title-sm">{{ translations.titleQuestion[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.industry40Chapter1[locale] }}</p>

            <h2 class="ma-4 global-title-sm">{{ translations.smartFutureTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.smartFutureTitleChapter1[locale] }}</p>
            <p class="white--text ma-4">{{ translations.smartFutureTitleChapter2[locale] }}</p>
            <p class="white--text ma-4" v-html="translations.smartFutureFootnote[locale]" />

            <h1 class="ma-4 global-title-sm hidden-md-and-up">{{ translations.resultsTitle[locale] }}</h1>
            <h1 class="ma-4 global-title hidden-sm-and-down">{{ translations.resultsTitle[locale] }}</h1>

            <i><p class="white--text ma-4">{{ translations.resultsDescription[locale] }}</p></i>

            <!-- Tiekartta kohti visiota 2028 -->

            <h1 class="ma-4 global-title-sm hidden-md-and-up">{{ translations.roadmapTitle[locale] }}</h1>
            <h1 class="ma-4 global-title hidden-sm-and-down">{{ translations.roadmapTitle[locale] }}</h1>

            <p class="white--text ma-4">{{ translations.visionsDescription1[locale] }}</p>

            <ol class="ma-4">
              <li class="white--text" v-for="vision in translations.visions[locale]" :key="vision">{{ vision }}</li>
            </ol>  

            <p class="white--text ma-4">{{ translations.visionsDescription2[locale] }}<br />{{ translations.visionsDescription3[locale] }}</p>
            <p class="white--text ma-4">{{ translations.visionsDescription4[locale] }}<br />{{ translations.visionQuote[locale] }}</p>

            <Expandable min-dialog-width="2500px">
              <v-img
              src="@/assets/ASFT_visualisoinnit_muokatut-02.png"
              contain
              >
              </v-img>
            </Expandable>

            <!-- VISIO 2028 -->

            <h1 class="ma-4 global-title-sm hidden-md-and-up">{{ translations.visio2028Title[locale] }}</h1>
            <h1 class="ma-4 global-title hidden-sm-and-down">{{ translations.visio2028Title[locale] }}</h1>

            <h2 class="ma-4 global-title-sm">{{ translations.visio2028CooperationTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.visio2028Cooperation[locale] }}</p>

            <h2 class="ma-4 global-title-sm">{{ translations.visio2028CircularEconomyTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.visio2028CircularEconomy[locale] }}</p>

            <h2 class="ma-4 global-title-sm">{{ translations.visio2028RegionalityTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.visio2028Regionality[locale] }}</p>

            <h2 class="ma-4 global-title-sm">{{ translations.visio2028EducationTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.visio2028Education[locale] }}</p>

            <h2 class="ma-4 global-title-sm">{{ translations.visio2028TechnologyTitle[locale] }}</h2>
            <p class="white--text ma-4">{{ translations.visio2028Technology[locale] }}</p>
            
            <Expandable max-card-width="900px" min-dialog-width="1500px">
              <v-img
              src="@/assets/ASFT_visualisoinnit_muokatut-09.png"
              contain
              max-width="1500px"
              >
              </v-img>
            </Expandable>

            <!-- YHTEISTYÖN TOIMINTAMALLI -->


            <h1 class="ma-4 global-title-sm hidden-md-and-up">{{ translations.coperationTitle[locale] }}</h1>
            <h1 class="ma-4 global-title hidden-sm-and-down">{{ translations.coperationTitle[locale] }}</h1>

            <p class="white--text ma-4">{{ translations.coperationDescription[locale] }}</p>

            <p class="white--text ma-4">{{ translations.coperationListTitle[locale] }}</p>

            <ol class="ma-4">
              <li class="white--text" v-for="coperation in translations.coperationList[locale]" :key="coperation">{{ coperation }}</li>
            </ol> 

            <p class="white--text ma-4">{{ translations.coperationContinue[locale] }}</p>

            <Expandable min-dialog-width="2500px">
              <v-img
              src="@/assets/ASFT_visualisoinnit_muokatut-01.png"
              contain
              >
              </v-img>
            </Expandable>

            <!-- YHTEISTYÖN TOIMINTAMALLI: Kärkiosaaminen -->

            <!--

            <h2 class="ma-4 global-title-sm">{{ translations.coperationArrowTitle[locale] }}</h2>

            <p class="white--text ma-4">{{ translations.coperationArrowChapter1[locale] }}</p>
            <p class="white--text ma-4">{{ translations.coperationArrowChapter2[locale] }}</p>
            <p class="white--text ma-4">{{ translations.coperationArrowChapter3[locale] }}</p>

            <Expandable>
              <v-img
              src="@/assets/ASFT_visualisoinnit_muokatut-08.png"
              contain
              >
              </v-img>
            </Expandable>

            -->


            <v-expansion-panels dark flat>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Kärkiosaaminen -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationArrowTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationArrowChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationArrowChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationArrowChapter3[locale] }}</p>

                  <Expandable max-card-width="600px" min-dialog-width="1000px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-08.png"
                    contain
                    max-width="1000px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Opetus -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationTeachingTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationTeachingChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationTeachingChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationTeachingChapter3[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationTeachingChapter4[locale] }}</p>

                  <Expandable max-card-width="800px" min-dialog-width="1000px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-07.png"
                    contain
                    max-width="1000px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Oppilaitokset -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationAcademeTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationAcademeChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationAcademeChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationAcademeChapter3[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationAcademeChapter4[locale] }}</p>

                  <Expandable max-card-width="800px" min-dialog-width="1000px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-05.png"
                    contain
                    max-width="1000px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Kehittämisympäristöt -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationInfrastuctureTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationInfrastuctureChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationInfrastuctureChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationInfrastuctureChapter3[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationInfrastuctureChapter4[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationInfrastuctureChapter5[locale] }}</p>

                  <Expandable max-card-width="1000px" min-dialog-width="1400px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-03.png"
                    contain
                    max-width="1400px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Hankeyhteistyöt -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationProjectsTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationProjectsChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationProjectsChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationProjectsChapter3[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationProjectsChapter4[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationProjectsChapter5[locale] }}</p>

                  <Expandable max-card-width="800px" min-dialog-width="1000px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-06.png"
                    contain
                    max-width="1000px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- YHTEISTYÖN TOIMINTAMALLI: Yritysyhteistyöt -->
              <v-expansion-panel>
                <v-expansion-panel-header color="var(--v-primary-base)">
                  <h2 class="ma-4 global-title-sm">{{ translations.coperationCompaniesTitle[locale] }}</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--v-primary-base)">
                  <p class="white--text ma-4">{{ translations.coperationCompaniesChapter1[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationCompaniesChapter2[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationCompaniesChapter3[locale] }}</p>
                  <p class="white--text ma-4">{{ translations.coperationCompaniesChapter4[locale] }}</p>

                  <Expandable max-card-width="800px" min-dialog-width="1000px">
                    <v-img
                    src="@/assets/ASFT_visualisoinnit_muokatut-04.png"
                    contain
                    max-width="1000px"
                    >
                    </v-img>
                  </Expandable>                
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>

          </v-col>
        </v-row>
  
      </v-container>
      <div class="bg-sm">
  
      </div>
    </div>
  </template>
  
  <style  scoped>
  .bg-sm {
    height: 300px;
    width: 100%;
  }
  .bg {
    height: 100%;
  }
  .br {
    background: var(--v-primary-base) !important;
  }
  .trl-case-sm {
    font-size: 12px;
  }
  .back {
    background: var(--v-primary-base) !important;
    padding: 32px;
    margin: 16px;
    width: 100%;
  }
  
  h2 {
    font-weight: 400;
    text-align: left;
  }
  h1 {
    text-align: left;
  }
  .title-info {
    color: #f7941e;
  }
  </style>
  
  <script>
  import { INDUSTRY_40 } from "../../constants/translations";
  import Expandable from "../../components/Expandable";
  
  export default {
    name: "Industry",
    components: { Expandable },
    data() {
      return {
        translations: INDUSTRY_40,
      };
    },
    computed: {
      locale() {
        return this.$store.state.locale;
      },
    },
  };
  </script>